import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { OnsiteContact } from '@boosterfuels/types/types-ts'

export interface OnsiteContactResponse {
  contacts: OnsiteContact[]
}

export interface FindForCustomerOnCompanyResponse {
  primary: string[]
  secondary: string[]
}

@Injectable({ providedIn: 'root' })
export class OnsiteContactApi {
  constructor(private http: HttpClient) {}

  findByFleetAccountId(fleetAccountId: string) {
    return this.http.get<OnsiteContactResponse>(
      '/OnsiteContact/findByFleetAccountId',
      { params: { fleetAccountId } }
    )
  }

  findForCustomerOnCompany(data: {
    fleetCompanyId: string
    fleetCustomerId: string
  }) {
    return this.http.get<FindForCustomerOnCompanyResponse>(
      '/OnsiteContact/findForCustomerOnCompany',
      { params: data }
    )
  }

  findForCustomer(fleetCustomerId: string) {
    return this.http.get<{ onsiteContacts: OnsiteContact[] }>(
      '/OnsiteContact/findForCustomer',
      { params: { fleetCustomerId } }
    )
  }

  bulkSaveContacts(contacts: OnsiteContact[]) {
    return this.http.post<OnsiteContactResponse>(
      '/OnsiteContact/bulkSaveContacts',
      { contacts }
    )
  }
}
